<template>
  <BirthdayTable
    :data="data"
    :page="page"
    :pageSize="pageSize"
    :contacts="today"
    :total="total"
    :handleCurrentChange="handleCurrentChange" />
</template>

<script>
  import BirthdayTable from "./BirthdatTable";
  export default {
    props: {
      today: {
        required: true,
        type: Array,
      },
      page: {
        required: true,
        type: Number,
      },
      pageSize: {
        required: true,
        type: Number,
      },
      total: {
        required: true,
        type: Number,
      },
      handleCurrentChange: {
        type: Function,
        required: true,
      },
    },
    name: "today",
    components: { BirthdayTable },
    data() {
      return {
        data: {
          message: "No Birthday contacts today.",
          contacts: this.today,
        },
      };
    },
  };
</script>

<style></style>
