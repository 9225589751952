<template>
  <div
    class="row justify-content-between page_medium_top_margin"
    id="__birthday__app">
    <div class="col-md-12 mb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="_top_navigation">
            <div class="_birtday_setup">
              <p class="_birthday_app">
                {{ $t("dashboard.birthday_app.birthday_app") }}
              </p>
              <p class="__no_of_contacts">
                {{
                  !loading
                    ? currentComponent == "BirthdayToday"
                      ? `${today.length} ${this.$t(
                          "dashboard.birthday_app.contact_birthday_today"
                        )}`
                      : currentComponent == "birthdayWeek"
                      ? `${thisWeek.length} ${this.$t(
                          "dashboard.birthday_app.contact_birthday_week"
                        )}`
                      : `${thisMonth.length} ${this.$t(
                          "dashboard.birthday_app.contact_birthday_month"
                        )}`
                    : ""
                }}
              </p>
            </div>
            <div class="middle_">
              <div class="search">
                <el-input
                  :placeholder="$t('dashboard.contacts.search.placeholder')"
                  class="search_item"
                  style="height: 40px !important"
                  v-model="search">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="_birthday_toggler">
                <button
                  :class="[
                    currentComponent == 'BirthdayToday' ? '__active' : '',
                  ]"
                  @click="toggleCurrentComponent('BirthdayToday')">
                  {{ $t("dashboard.birthday_app.birthday_today") }}
                </button>
                <button
                  :class="[
                    currentComponent == 'birthdayWeek' ? '__active' : '',
                  ]"
                  @click="toggleCurrentComponent('birthdayWeek')">
                  {{ $t("dashboard.birthday_app.birthday_week") }}
                </button>
                <button
                  :class="[
                    currentComponent == 'BirthdayThisMonth' ? '__active' : '',
                  ]"
                  @click="toggleCurrentComponent('BirthdayThisMonth')">
                  {{ $t("dashboard.birthday_app.birthday_month") }}
                </button>
              </div>
            </div>
            <div class="_sid_nav">
              <button
                id="__birth_day_settings"
                @click="
                  $router.push('/birthday-settings/save').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                ">
                <img
                  src="../../../assets/Setting_l.svg"
                  alt="gear icon for settings" />
                {{ $t("dashboard.birthday_app.birthday_app_settings") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 _card_top_wrappert __main_messages">
      <div id="__message_voice_setttings" class="mnotify-card">
        <div class="mnotify-card-body">
          <div class="__birday_app__initial__state" v-if="loading">
            <ComponentLoader :loading="loading" />
          </div>
          <div class="" v-else>
            <div class="col-md-12" v-if="currentComponent == 'BirthdayToday'">
              <div class="__all__messages" v-if="displayTodayData.length">
                <div class="contact_table">
                  <el-table
                    :header-cell-style="{
                      background: 'rgba(212, 216, 226, 0.1)',
                    }"
                    :data="displayTodayData"
                    ref="singleTable"
                    height="60vh">
                    <el-table-column
                      :label="
                        $t('dashboard.send_message.international_messages.name')
                      "
                      width="min-content">
                      <template slot-scope="scope">
                        {{ scope.row.firstname }}
                        {{ scope.row.lastname }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="phone"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.phone'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.email'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="date_of_birth"
                      :label="$t('dashboard.contacts.date_of_birth')"
                      width="min-content">
                    </el-table-column>
                  </el-table>
                  <div class="" style="text-align: center; margin-top: 2em">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :page-size="pageSize"
                      :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="currentComponent == 'BirthdayThisMonth'">
              <div class="__all__messages" v-if="displayThisMOnthData.length">
                <div class="contact_table">
                  <el-table
                    :header-cell-style="{
                      background: 'rgba(212, 216, 226, 0.1)',
                    }"
                    :data="displayThisMOnthData"
                    ref="singleTable"
                    height="60vh">
                    <el-table-column
                      :label="
                        $t('dashboard.send_message.international_messages.name')
                      "
                      width="min-content">
                      <template slot-scope="scope">
                        {{ scope.row.firstname }}
                        {{ scope.row.lastname }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="phone"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.phone'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.email'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="date_of_birth"
                      :label="$t('dashboard.contacts.date_of_birth')"
                      width="min-content">
                    </el-table-column>
                  </el-table>
                  <div class="" style="text-align: center; margin-top: 2em">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :page-size="pageSize"
                      :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
            <!-- This week -->
            <div class="col-md-12" v-if="currentComponent == 'birthdayWeek'">
              <div class="__all__messages" v-if="displayThisWeekData.length">
                <div class="contact_table">
                  <el-table
                    :header-cell-style="{
                      background: 'rgba(212, 216, 226, 0.1)',
                    }"
                    :data="displayThisWeekData"
                    ref="singleTable"
                    height="60vh">
                    <el-table-column
                      :label="
                        $t('dashboard.send_message.international_messages.name')
                      "
                      width="min-content">
                      <template slot-scope="scope">
                        {{ scope.row.firstname }}
                        {{ scope.row.lastname }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="phone"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.phone'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      :label="
                        $t(
                          'dashboard.send_message.message_templates.form.content.email'
                        )
                      "
                      width="min-content">
                    </el-table-column>
                    <el-table-column
                      prop="date_of_birth"
                      :label="$t('dashboard.contacts.date_of_birth')"
                      width="min-content">
                    </el-table-column>
                  </el-table>
                  <div class="" style="text-align: center; margin-top: 2em">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :page-size="pageSize"
                      :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import SmsMessages from "./smsmessages.vue";
  import VoiceMessages from "./voicesms.vue";
  import BirthdayToday from "./BirthdayToday";
  import BirthdayThisMonth from "./BirthDaysThisMonth";
  import birthdayWeek from "./birthdayWeek.vue";
  import { get } from "lodash";
  export default {
    name: "birthday-settings",
    components: {
      SmsMessages,
      VoiceMessages,
      BirthdayToday,
      BirthdayThisMonth,
      birthdayWeek,
    },
    data() {
      return {
        loading: false,
        search: "",
        currentComponent: "BirthdayToday",
        today: [],
        thisMonth: [],
        thisWeek: [],
        page: 1,
        pageSize: 20,
        total: 0,
      };
    },
    mounted() {
      this.getThisMonthCelebrants();
      this.getTodayCelebrants();
      this.getWeekCelebrants();
    },

    computed: {
      searchingToday() {
        if (!this.search) {
          this.total = this.today.length;
          return this.today;
        }
        this.page = 1;
        return this.today.filter(
          (data) =>
            data.firstname.toLowerCase().includes(this.search.toLowerCase()) ||
            data.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
            data.phone.toLowerCase().includes(this.search.toLowerCase())
        );
      },
      displayTodayData() {
        this.total = this.searchingToday.length;
        return this.searchingToday.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      searchingThisMonth() {
        if (!this.search) {
          this.total = this.thisMonth.length;
          return this.thisMonth;
        }
        this.page = 1;
        return this.thisMonth.filter(
          (data) =>
            data.firstname.toLowerCase().includes(this.search.toLowerCase()) ||
            data.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
            data.phone.toLowerCase().includes(this.search.toLowerCase())
        );
      },
      displayThisMOnthData() {
        this.total = this.searchingThisMonth.length;
        return this.searchingThisMonth.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      displayThisWeekData() {
        this.total = this.thisWeek.length;
        return this.thisWeek;
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      async getThisMonthCelebrants() {
        try {
          const { data } = await store.dispatch(
            "birthday/getThisMonthCelebrants"
          );
          this.thisMonth = data;
        } catch (error) {}
      },
      async getTodayCelebrants() {
        try {
          this.loading = true;
          const { data } = await store.dispatch("birthday/getTodayCelebrants");
          this.today = data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      async getWeekCelebrants() {
        try {
          const { data } = await store.dispatch("birthday/getWeekCelebrants");
          this.thisWeek = data;
        } catch (error) {}
      },
      toggleCurrentComponent(value) {
        this.currentComponent = value;
      },
    },
  };
</script>

<style lang="scss">
  ._loadin_state {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._sid_nav {
    height: inherit;
  }

  #__birthday__app {
    #__message_voice_setttings {
      height: 785px;
    }
  }

  ._top_navigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .middle_ {
      display: flex;
    }

    ._birthday_app {
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 159.3%;
      color: #575761;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .__no_of_contacts {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: normal;
      color: #777b83;
    }

    ._birthday_toggler {
      background: #ffffff;
      border-radius: 5px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0.5em;
      margin-left: 1em;

      button {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 159.3%;
        text-align: center;
        outline: none;
        border: none;
        background-color: transparent;
        padding-right: 0.8em;
        padding-left: 0.8em;
      }
    }
  }

  .__active {
    font-weight: 600;
    color: #f7921c;
    background: rgba(247, 146, 28, 0.1) !important;
    border-radius: 5px;
  }

  #__birth_day_settings {
    background: #ffffff;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    height: 50px;
    text-transform: capitalize;
    color: #484a4f;
    float: right;
    padding: 1em 2em;
    outline: none;
    border: none;

    img {
      margin-right: 2em;
    }
  }

  @media screen and (max-width: 600px) {
    ._top_navigation {
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    ._birtday_setup {
      margin-bottom: 1.5em;
    }

    .middle_ {
      display: flex;
      flex-direction: column;
    }

    ._birthday_toggler {
      margin-left: 0px !important;
      margin-top: 1.5em;
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: 1185px) {
    #__birth_day_settings {
      margin-top: 1em;
    }
  }

  @media screen and (max-width: 330px) {
    ._birthday_toggler {
      button {
        font-size: 12px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .create_sender {
      margin-bottom: 1em;
    }
  }
</style>
