var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('BirthdayTable', {
    attrs: {
      "data": _vm.data,
      "page": _vm.page,
      "pageSize": _vm.pageSize,
      "contacts": _vm.thisMonth,
      "total": _vm.total,
      "handleCurrentChange": _vm.handleCurrentChange
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };