<template>
  <div class="col-md-12">
    <div class="__empty__state" v-if="!data.contacts.length">
      <div class="__nodata_messages">
        <div class="_no_message_header">
          <div class="table-responsive-sm">
            <table class="">
              <thead class="__mnotify__table__header">
                <tr>
                  <th class=" ">Name</th>
                  <th class="">Mobile</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="no_message_message">
          <h3 class="__nodata__header text-center">{{ data.message }}</h3>
        </div>
      </div>
    </div>
    <div class="__all__messages" v-if="contacts.length">
      <div class="contact_table">
        <el-table
          :header-cell-style="{ background: 'rgba(212, 216, 226, 0.1)' }"
          :data="contacts"
          ref="singleTable"
          style="width: 100%; min-width: 100vw"
          height="60vh"
          width="100%">
          <el-table-column width="25%">
            <template slot-scope="scope">
              {{ scope.row.firstname }}
              {{ scope.row.lastname }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="Phone" width="100%">
          </el-table-column>
          <el-table-column prop="email" label="Email" width="100%">
          </el-table-column>
          <el-table-column
            prop="date_of_birth"
            label="Date of Birth"
            width="100%">
          </el-table-column>
        </el-table>
        <div class="" style="text-align: center; margin-top: 2em">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changeCurrent"
            :page-size="pageSize"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        required: true,
        type: Object,
      },
      contacts: {
        type: Array,
        required: true,
      },
      page: {
        required: true,
        type: Number,
      },
      pageSize: {
        required: true,
        type: Number,
      },
      total: {
        required: true,
        type: Number,
      },
      handleCurrentChange: {
        type: Function,
        required: true,
      },
    },
    methods: {
      changeCurrent() {
        this.handleCurrentChange();
      },
    },
  };
</script>

<style scoped lang="scss">
  .no_message_message {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 15em;
    align-items: center;
    justify-content: center;
    width: 100%;

    h3 {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    .no_message_message {
      margin-top: 5em;
    }
  }
</style>
